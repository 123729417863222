.footer-container {
    background-color: #282c34;
    padding: 2rem 0;
    color: #fff;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: center;
  }
  
  .footer-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  
  .footer-text {
    margin: 1rem 0;
    font-size: 0.9rem;
    line-height: 1.4;
  }
  
  .footer-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .footer-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s;
  
    &:hover {
      color: #0077cc;
    }
  }
  