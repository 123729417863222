.navbar {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(10, 10, 20, 0.8); /* Semi-transparent backdrop */
    position: relative;
    z-index: 101;
}

.navbar-content {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: whitesmoke;
    text-decoration: none;
    z-index: 500;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
}

.navbar-list {
    list-style: none;
    display: flex;
    text-decoration: none;
    gap: 20px;
    position: absolute;
    
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(10, 10, 20, 0.95); /* Slightly visible backdrop */
    border-top: 2px solid #00FFA3;
    transform: translateY(-400%);
    transition: transform 0.3s ease-in-out;
}

.navbar-list.active {
    transform: translateY(0);
    
}

.navbar-item {
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    flex: 1;
    text-align: center;
}

.link_main {
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    color: whitesmoke;
}

.navbar-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #00FFA3; /* Bright green accent */
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.25s ease;
}

.navbar-item:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.menu-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: whitesmoke;
    cursor: pointer;
    outline: none;
}

/* Responsive styles */
@media (min-width: 768px) {
    .menu-toggle {
        display: none;
    }

    .navbar-list {
        position: static;
        background: none;
        border-top: none;
        transform: translateY(0);
        
    }
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }
    .navbar-list{
        flex-direction: column;
        z-index: 2;
        transform: translateY(-400px);
    }

    .navbar-list.active{
        transform: translateY(-20px);

    }
}
