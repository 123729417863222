.services-section {
    padding: 100px 0;
    background: linear-gradient(135deg, #1a1a2e, #121212);
    text-align: center;
    color: white;
    overflow-x: hidden;
}

.services-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 20px;
    padding: 20px;
    scrollbar-width: none; /* Firefox */
}

.services-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
}

.service-card {
    flex: 0 0 auto;
    width: 300px;
    padding: 20px;
    margin: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transform: skew(-10deg) translateY(20px);
    transition: transform 0.5s;
}

.service-card.even {
    transform: skew(10deg) translateY(-20px);
}

.service-card img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.service-content {
    text-align: left;
}

.service-card h3 {
    margin-top: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .services-container {
        flex-direction: column;
        align-items: center;
        overflow-x: unset;
    }

    .service-card {
        width: 80%;
    }

    .service-card.even, .service-card.odd {
        transform: none;
    }
}
