.hero-section {
    height: calc(100vh - 60px); 
    background: linear-gradient(135deg, #1a1a2e, #121212); /* Placeholder gradient, replace with image if preferred */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.hero-content {
    text-align: center;
    max-width: 800px;
    z-index: 2;
    text-decoration: none;
}

h1 {
    font-size: 3rem;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

p {
    font-size: 1.25rem;
    color: #AAAAAA;
    margin-bottom: 2rem;
}

.cta-button {
    background-color: #00DD93;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
}

.cta-button:hover {
    background-color: #057550;
}

/* If you decide to use an SVG or image for the "illuminated path", 
you can position it using absolute positioning within the hero-section. */

