.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1a1a2e, #121212);
    padding: 50px 0;
    color: whitesmoke;
  }
  
  .content-box {
    display: flex;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .company-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 30px;
    transition: transform 0.3s ease;
  }
  
  .company-image:hover {
    transform: scale(1.1);
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .description:hover {
    text-decoration: underline;
  }
  
  /* Mobile view */
  @media (max-width: 768px) {
    .content-box {
      flex-direction: column;
    }
    .company-image {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  