.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  
  .contact-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .contact-header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .contact-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 5px;
    transition: transform 0.3s ease;
  
    /* On hover, slightly elevate the card */
    &:hover {
      transform: translateY(-5px);
    }
  }
  
  .contact-icon {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  a {
    text-decoration: none;
    color: #0077cc;
  
    &:hover {
      text-decoration: underline;
    }
  }
  